/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'site',
        title: 'Site',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id   : 'site-contatos',
                title: 'Contatos',
                type : 'basic',
                icon : 'heroicons_outline:phone',
                link : '/site/contatos'
            },
            {
                id   : 'site-conteudos',
                title: 'Conteúdos',
                type : 'collapsable',
                icon : 'heroicons_outline:document-duplicate',
                link : '/example',
                children: [
                    {
                        id: 'site-conteudos-webinar',
                        title: 'Calendário do Endomarketing',
                        type: 'basic',
                        icon : 'heroicons_outline:calendar',
                        link : '/example'
                    },
                    {
                        id: 'site-conteudos-webinar',
                        title: 'Webinários',
                        type: 'basic',
                        icon : 'heroicons_outline:video-camera',
                        link : '/example'
                    },
                    {
                        id: 'site-conteudos-webinar',
                        title: 'Ebooks',
                        type: 'basic',
                        icon : 'heroicons_outline:book-open',
                        link : '/example'
                    },
                ]
            }
        ]
    },
    {
        id: 'plataformas',
        title: 'Plataformas',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id   : 'site-contatos',
                title: 'Plataformas',
                type : 'basic',
                icon : 'heroicons_outline:server',
                link : '/plataforma/index'
            },
            {
                id   : 'site-contatos',
                title: 'Clientes',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/plataforma/clientes'
            },
            {
                id   : 'site-contatos',
                title: 'Planos',
                type : 'basic',
                icon : 'heroicons_outline:color-swatch',
                link : '/plataforma/planos'
            },
            {
                id   : 'site-contatos',
                title: 'Cadastros Inválidos',
                type : 'basic',
                icon : 'heroicons_outline:exclamation',
                link : '/plataforma/cadastros-invalidos'
            },
        ]
    },

    {
        id: 'plataformas',
        title: 'Comercial',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'comercial',
                title: 'Leads',
                type: 'basic',
                icon: 'heroicons_outline:zoom-in',
                link: '/example'
            },
        ]
    },

    {
        id: 'plataformas',
        title: 'Financeiro',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'financeiro',
                title: 'Cobranças',
                type: 'basic',
                icon: 'heroicons_outline:receipt-tax',
                link: '/financeiro/cobranca'
            }
        ]
    },

    {
        id: 'configuracoes',
        title: 'Configurações',
        subtitle: 'Avisos, Emails, Usuários...',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id   : 'site-contatos',
                title: 'Avisos',
                type : 'basic',
                icon : 'heroicons_outline:exclamation-circle',
                link : '/example'
            },
            {
                id   : 'site-conteudos',
                title: 'Emails',
                type : 'collapsable',
                icon : 'heroicons_outline:mail',
                link : '/example',
                children: [
                    {
                        id: 'site-conteudos-webinar',
                        title: 'Enviados',
                        type: 'basic',
                        icon : 'heroicons_outline:upload',
                        link : '/example'
                    },
                    {
                        id: 'site-conteudos-webinar',
                        title: 'Blacklist',
                        type: 'basic',
                        icon : 'heroicons_outline:x-circle',
                        link : '/example'
                    },
                ]
            },
            {
                id: 'site-conteudos',
                title: 'Usuários',
                type: 'basic',
                icon: 'heroicons_outline:users',
                link : '/example'
            }
        ]
    },
    ];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];